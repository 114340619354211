// hubspot-to-excel/my-chart-app/src/components/TrendView.js
import React, { useState, useEffect, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import { ArrowUpIcon, ArrowDownIcon, ArrowRightIcon, RefreshCw, Download, X } from 'lucide-react';
import axios from 'axios';

const TrendView = ({ selectedProperty, isVisible, onClose }) => {
  const [trendData, setTrendData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isHistoricalFetching, setIsHistoricalFetching] = useState(false);

  const fetchTrendData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get('/api/trend-analysis', {
        params: { property: selectedProperty }
      });
      setTrendData(response.data);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [selectedProperty]);

  const fetchHistoricalData = async () => {
    try {
      setIsHistoricalFetching(true);
      const today = new Date();
      const endDate = today;
      const startDate = new Date(today.getFullYear(), today.getMonth() - 2, 1);

      await axios.get('/api/fetch-historical-contacts', {
        params: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        }
      });

      await fetchTrendData();
      setError(null);
    } catch (err) {
      setError(`Error fetching historical data: ${err.message}`);
    } finally {
      setIsHistoricalFetching(false);
    }
  };

  useEffect(() => {
    if (isVisible) {
      fetchTrendData();
    }
  }, [isVisible, fetchTrendData]);

  const getChangeColor = (value) => {
    if (value > 0) return 'text-green-400';
    if (value < 0) return 'text-red-400';
    return 'text-gray-400';
  };

  const getChangeIcon = (value) => {
    if (value > 0) return <ArrowUpIcon className="w-4 h-4 text-green-400" />;
    if (value < 0) return <ArrowDownIcon className="w-4 h-4 text-red-400" />;
    return <ArrowRightIcon className="w-4 h-4 text-gray-400" />;
  };

  if (!isVisible) return null;

  const renderTrendChart = (trendData) => {
    const months = Object.values(trendData.trends)[0].months;

    const datasets = Object.entries(trendData.trends).map(([category, data], index) => ({
      label: category,
      data: data.values,
      borderColor: `hsl(${(index * 360) / Object.keys(trendData.trends).length}, 70%, 50%)`,
      tension: 0.1,
      fill: false
    }));

    return (
      <Line
        data={{
          labels: months,
          datasets: datasets
        }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top',
              labels: {
                color: '#e5e7eb',
                font: { size: 12 },
                padding: 20
              }
            },
            title: {
              display: true,
              text: 'Historical Trend Analysis',
              color: '#e5e7eb',
              font: { size: 16, weight: 'bold' },
              padding: { bottom: 20 }
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const label = context.dataset.label;
                  const value = context.raw;
                  const percentageChange = trendData.trends[label].percentageChanges[context.dataIndex];
                  return `${label}: ${value} (${percentageChange.toFixed(1)}% change)`;
                }
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: { 
                precision: 0,
                color: '#e5e7eb',
                font: { size: 11 }
              },
              grid: {
                color: 'rgba(55, 65, 81, 0.5)',
                drawBorder: false
              }
            },
            x: {
              ticks: {
                color: '#e5e7eb',
                font: { size: 11 }
              },
              grid: {
                display: false
              }
            }
          }
        }}
        height={300}
      />
    );
  };

  const renderSummaryCards = (trendData) => {
    return Object.entries(trendData.trends).map(([category, data]) => {
      const lastChange = data.percentageChanges[data.percentageChanges.length - 1];
      const currentValue = data.values[data.values.length - 1];
      
      return (
        <div key={category} className="bg-gray-700/30 rounded-lg p-4">
          <div className="flex justify-between items-start">
            <div>
              <h3 className="font-medium text-gray-200">{category}</h3>
              <div className="mt-1 flex items-center space-x-1">
                {getChangeIcon(lastChange)}
                <span className={`text-sm ${getChangeColor(lastChange)}`}>
                  {Math.abs(lastChange).toFixed(1)}% from previous month
                </span>
              </div>
            </div>
            <div className="text-xl font-bold text-gray-200">
              {currentValue}
            </div>
          </div>
        </div>
      );
    });
  };

  const renderMonthlyTotals = (trendData) => {
    const monthlyTotals = trendData.monthlyTotals;
    const monthNames = Object.values(trendData.trends)[0].months;

    return (
      <div className="bg-gray-700/30 rounded-lg p-4">
        <h3 className="text-lg font-medium text-gray-200 mb-4">Total Contacts</h3>
        <div className="grid grid-cols-3 gap-4">
          {monthlyTotals.map((total, index) => (
            <div key={index} className="text-center">
              <div className="text-sm text-gray-400">
                {monthNames[index]}
              </div>
              <div className="text-xl font-bold text-gray-200">{total}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-gray-800 rounded-xl shadow-2xl p-6 m-4 max-w-6xl w-full max-h-[90vh] overflow-y-auto border border-gray-700">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold text-blue-400">Trend Analysis</h2>
          <div className="flex items-center gap-4">
            <button
              onClick={fetchHistoricalData}
              disabled={isHistoricalFetching}
              className="flex items-center gap-2 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg disabled:opacity-50 transition-colors duration-200"
            >
              <Download className={`w-4 h-4 ${isHistoricalFetching ? 'animate-bounce' : ''}`} />
              {isHistoricalFetching ? 'Fetching History...' : 'Update Data'}
            </button>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-200 transition-colors duration-200"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
        </div>

        {error && (
          <div className="bg-red-900/50 border-l-4 border-red-500 p-4 mb-4 rounded-r-lg">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-300">{error}</p>
              </div>
            </div>
          </div>
        )}

        {isLoading || isHistoricalFetching ? (
          <div className="flex items-center justify-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-400"></div>
          </div>
        ) : trendData && (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {renderSummaryCards(trendData)}
            </div>
            <div className="bg-gray-700/30 rounded-lg p-4">
              {renderTrendChart(trendData)}
            </div>
            {renderMonthlyTotals(trendData)}
          </div>
        )}
      </div>
    </div>
  );
};

export default TrendView;