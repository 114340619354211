import React from 'react';
import { addDays, format, startOfMonth, endOfMonth, startOfYear, endOfYear, subMonths, subYears } from 'date-fns';
import { Calendar } from 'lucide-react';

const CustomDatePicker = ({ startDate, endDate, onDateChange }) => {
  // Preset date ranges
  const presets = [
    {
      label: 'Today',
      getValue: () => ({ start: new Date(), end: new Date() })
    },
    {
      label: 'Last 7 days',
      getValue: () => ({ start: addDays(new Date(), -6), end: new Date() })
    },
    {
      label: 'Last 30 days',
      getValue: () => ({ start: addDays(new Date(), -29), end: new Date() })
    },
    {
      label: 'This month',
      getValue: () => ({ start: startOfMonth(new Date()), end: endOfMonth(new Date()) })
    },
    {
      label: 'Last month',
      getValue: () => {
        const lastMonth = subMonths(new Date(), 1);
        return { start: startOfMonth(lastMonth), end: endOfMonth(lastMonth) };
      }
    },
    {
      label: 'Last 3 months',
      getValue: () => ({ start: subMonths(new Date(), 3), end: new Date() })
    },
    {
      label: 'This year',
      getValue: () => ({ start: startOfYear(new Date()), end: endOfYear(new Date()) })
    },
    {
      label: 'Last year',
      getValue: () => {
        const lastYear = subYears(new Date(), 1);
        return { start: startOfYear(lastYear), end: endOfYear(lastYear) };
      }
    }
  ];

  const handlePresetChange = (e) => {
    const preset = presets.find(p => p.label === e.target.value);
    if (preset) {
      const { start, end } = preset.getValue();
      onDateChange(start, end);
    }
  };

  return (
    <div className="grid gap-4">
      <div className="flex flex-col gap-2">
        <label className="block text-sm font-medium text-gray-300">Date Range</label>
        <div className="flex flex-wrap gap-3">
          {/* Date Presets Dropdown */}
          <select
            onChange={handlePresetChange}
            className="bg-gray-700 border border-gray-600 text-gray-200 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 hover:bg-gray-600 transition-colors"
          >
            <option value="">Select range</option>
            {presets.map((preset) => (
              <option key={preset.label} value={preset.label}>
                {preset.label}
              </option>
            ))}
          </select>

          {/* Custom Date Range Inputs */}
          <div className="flex gap-2">
            <div className="relative">
              <input
                type="date"
                value={format(startDate, 'yyyy-MM-dd')}
                onChange={(e) => onDateChange(new Date(e.target.value), endDate)}
                className="bg-gray-700 border border-gray-600 text-gray-200 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 pl-10 hover:bg-gray-600 transition-colors"
              />
              <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
            </div>

            <span className="flex items-center text-gray-400">to</span>

            <div className="relative">
              <input
                type="date"
                value={format(endDate, 'yyyy-MM-dd')}
                onChange={(e) => onDateChange(startDate, new Date(e.target.value))}
                className="bg-gray-700 border border-gray-600 text-gray-200 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 pl-10 hover:bg-gray-600 transition-colors"
              />
              <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
            </div>
          </div>
        </div>

        {/* Display Selected Range */}
        <div className="text-sm text-gray-400">
          Selected: {format(startDate, 'MMM dd, yyyy')} - {format(endDate, 'MMM dd, yyyy')}
        </div>
      </div>
    </div>
  );
};

export default CustomDatePicker;