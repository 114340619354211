// hubspot-to-excel/my-chart-app/src/components/ChartComponent.js
import React, { useEffect, useState } from 'react';
import { Bar, Pie, Line } from 'react-chartjs-2';
import { BarChart3, PieChart, LineChart, CalendarIcon, RefreshCcw, TrendingUp, Users, Activity, Calendar } from 'lucide-react';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import CustomDatePicker from './CustomDatePicker';

import TrendView from './TrendView';
import axios from 'axios';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const ChartComponent = () => {
  const [chartData, setChartData] = useState(null);
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(endOfMonth(new Date()));
  const [selectedProperty, setSelectedProperty] = useState('stage_group');
  const [chartType, setChartType] = useState('Bar');
  const [error, setError] = useState(null);
  const [owners, setOwners] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState('All');
  const [isLoading, setIsLoading] = useState(false);
  const [totalContacts, setTotalContacts] = useState(0);
  const [showTrendView, setShowTrendView] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);

  const chartIcons = {
    Bar: <BarChart3 className="w-4 h-4" />,
    Pie: <PieChart className="w-4 h-4" />,
    Line: <LineChart className="w-4 h-4" />
  };

  // Fetch owners
  const fetchOwners = async () => {
    try {
      console.log('Fetching owners...');
      const response = await axios.get('/api/owners');
      console.log('Owners fetched:', response.data);
      setOwners(response.data);
    } catch (err) {
      console.error('Error fetching owners:', err);
      setError(err);
    }
  };

  // Fetch new contacts
  const fetchNewContacts = async () => {
    try {
      setIsLoading(true);
      await axios.get('/api/fetch-contacts', {
        params: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
      });
      await fetchData();
      setError(null);
    } catch (err) {
      console.error('Error fetching new contacts:', err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

 // Initial setup
 useEffect(() => {
  const initialize = async () => {
    await fetchOwners();
    await fetchData();
    setIsInitialized(true);
  };
  initialize();
}, []); // Empty dependency array means this only runs once

   // Update chart when filters change
   useEffect(() => {
    if (isInitialized) {
      fetchData();
    }
  }, [selectedProperty, selectedOwner, startDate, endDate]);

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  // Update the fetchData function to use dark mode colors
  const fetchData = async () => {
    try {
      console.log('Fetching chart data...');
      const response = await axios.get('/api/chart-data', {
        params: {
          property: selectedProperty,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          owner: selectedOwner, // Add owner parameter
        },
      });

      if (!response.data.propertyCounts) {
        throw new Error('No data available');
      }

      const { propertyCounts, totalContacts: total } = response.data;
      console.log('Chart data fetched:', propertyCounts, 'Total contacts:', total);

      // Modern dark theme colors
      const stageColors = {
        Opportunity: 'hsla(199, 89%, 48%, 0.8)',
        Working: 'hsla(145, 89%, 48%, 0.8)',
        Pitched: 'hsla(45, 89%, 48%, 0.8)',
        Revive: 'hsla(280, 89%, 48%, 0.8)',
        Customer: 'hsla(328, 89%, 48%, 0.8)',
        'No Value': 'hsla(0, 0%, 60%, 0.8)'
      };

      const stageBorderColors = {
        Opportunity: 'hsla(199, 89%, 58%, 1)',
        Working: 'hsla(145, 89%, 58%, 1)',
        Pitched: 'hsla(45, 89%, 58%, 1)',
        Revive: 'hsla(280, 89%, 58%, 1)',
        Customer: 'hsla(328, 89%, 58%, 1)',
        'No Value': 'hsla(0, 0%, 70%, 1)'
      };

      setChartData({
        labels: Object.keys(propertyCounts),
        datasets: [
          {
            label: 'Number of Contacts',
            data: Object.values(propertyCounts),
            backgroundColor: Object.keys(propertyCounts).map(key => stageColors[key] || 'hsla(200, 89%, 48%, 0.8)'),
            borderColor: Object.keys(propertyCounts).map(key => stageBorderColors[key] || 'hsla(200, 89%, 58%, 1)'),
            borderWidth: 1,
          },
        ],
      });

      setTotalContacts(total);
      setError(null);
    } catch (err) {
      console.error('Error fetching chart data:', err);
      setError(err);

      if (err.response?.status === 404 && !isInitialized) {
        await fetchNewContacts();
      }
    }
  };

  // Filter data based on selectedOwner
  const filterData = (data, owner) => {
    if (owner === 'All') {
      setFilteredData(data);
    } else {
      const filtered = data.filter(item => item.owner === owner);
      setFilteredData(filtered);
    }
  };

  // Use effect to filter data whenever selectedOwner changes
  useEffect(() => {
    filterData(originalData, selectedOwner);
  }, [selectedOwner, originalData]);

  return (
    <div className="h-full flex flex-col bg-gray-900 text-gray-100">
      <div className="flex-1 p-3">
        {/* Main Dashboard Card */}
        <div className="bg-gray-800 rounded-xl shadow-2xl p-4 mb-3 border border-gray-700">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-semibold text-blue-400">
              HubSpot Analytics Dashboard
            </h1>
            <button
              onClick={() => setShowTrendView(true)}
              className="flex items-center gap-2 bg-purple-600 hover:bg-purple-700 text-white font-medium py-1.5 px-3 rounded-md text-sm transition-colors duration-200"
            >
              <TrendingUp className="w-3.5 h-3.5" />
              See Trends
            </button>
          </div>
  
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {/* Left Column - Controls */}
            <div className="space-y-4">
              {/* Stats Card */}
          
  
              {/* Controls Grid */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {/* Property Selector */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">Property</label>
                  <select
                    value={selectedProperty}
                    onChange={(e) => setSelectedProperty(e.target.value)}
                    className="w-full rounded-md border border-gray-600 bg-gray-700/50 py-1.5 px-3 text-gray-200 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all duration-200"
                  >
                    <option value="stage_group">Stage Group</option>
                    <option value="hs_lead_status">Lead Status</option>
                    <option value="hs_analytics_source">Analytics Source</option>
                    <option value="country">Country</option>
                    <option value="hubspot_owner_id">Contact Owner</option>
                  </select>
                </div>
  
                {/* Chart Type Selector */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">Chart Type</label>
                  <select
                    value={chartType}
                    onChange={(e) => setChartType(e.target.value)}
                    className="w-full rounded-lg border border-gray-600 bg-gray-700/50 py-1.5 px-3 text-gray-200 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all duration-200"
                  >
                    {Object.entries(chartIcons).map(([type, icon]) => (
                      <option key={type} value={type}>{type} Chart</option>
                    ))}
                  </select>
                </div>
              </div>
  
              {/* Date Range Controls */}
              <div className="space-y-2">
  <label className="block text-sm font-medium text-gray-300">Date Range</label>
  <CustomDatePicker
    startDate={startDate}
    endDate={endDate}
    onDateChange={handleDateChange}
  />
</div>
  
              {/* Owner Selector */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-300">Contact Owner</label>
                <select
                  value={selectedOwner}
                  onChange={(e) => setSelectedOwner(e.target.value)}
                  className="w-full rounded-lg border border-gray-600 bg-gray-700/50 py-1.5 px-3 text-gray-200 shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all duration-200"
                >
                  <option value="All">All Owners</option>
                  {owners.map(owner => (
                    <option key={owner.id} value={owner.id}>{owner.name}</option>
                  ))}
                </select>
              </div>
  
              {/* Fetch Button */}
              <button
                onClick={fetchNewContacts}
                disabled={isLoading}
                className="w-full flex items-center justify-center gap-2 bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-lg shadow-sm transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <RefreshCcw className={`w-4 h-4 ${isLoading ? 'animate-spin' : ''}`} />
                {isLoading ? 'Loading...' : 'Fetch New Contacts'}
              </button>
            </div>
  
            {/* Right Column - Quick Stats */}
            <div className="bg-gray-800/50 rounded-lg p-4">
              <h3 className="text-base font-medium text-gray-200 flex items-center gap-2 mb-4">
                <Activity className="w-4 h-4" />
                Quick Stats
              </h3>
              <div className="grid grid-cols-1 gap-4">
                <div className="bg-gray-700/30 p-4 rounded-lg">
                  <div className="text-sm text-gray-400 mb-1">Date Range</div>
                  <div className="text-base font-medium text-gray-200">
                    {format(startDate, 'MMM dd')} - {format(endDate, 'MMM dd, yyyy')}
                  </div>
                </div>
                
                <div className="bg-gray-700/30 p-4 rounded-lg">
                  <div className="text-sm text-gray-400 mb-1">Selected Property</div>
                  <div className="text-base font-medium text-gray-200">
                    {selectedProperty.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                  </div>
                </div>
                
                <div className="bg-gray-700/30 p-4 rounded-lg">
                  <div className="text-sm text-gray-400 mb-1">Active Filters</div>
                  <div className="text-base font-medium text-gray-200">
                    {selectedOwner === 'All' ? 'All Owners' : 'Filtered by Owner'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        {/* Error Display */}
        {error && (
          <div className="bg-red-900/50 border-l-4 border-red-500 p-4 mb-3 rounded-r-lg">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-300">{error.message}</p>
              </div>
            </div>
          </div>
        )}
  
        {/* Chart Display */}
        <div className="bg-gray-800 rounded-xl shadow-2xl p-4 border border-gray-700">
          <div className="h-[50vh] flex flex-col">
            <div className="mb-4">
              <h2 className="text-lg font-semibold text-gray-200 flex items-center gap-2">
                <BarChart3 className="w-4 h-4" />
                Opportunity Report
                <span className="text-sm font-normal text-gray-400 ml-2">
                  Total Contacts in Range:{' '}
                  <span className="text-blue-400 font-semibold">{totalContacts}</span>
                </span>
              </h2>
            </div>
            <div className="flex-1">
              {chartData ? (
                React.createElement(
                  { Bar, Pie, Line }[chartType],
                  {
                    data: chartData,
                    options: {
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          position: 'top',
                          labels: {
                            color: '#e5e7eb',
                            font: { size: 12 },
                            padding: 20
                          }
                        },
                        title: {
                          display: true,
                          text: `Contact Stage Distribution`,
                          color: '#e5e7eb',
                          font: { size: 16, weight: 'bold' },
                          padding: { bottom: 20 }
                        },
                        datalabels: {
                          anchor: 'end',
                          align: 'top',
                          formatter: (value, context) => {
                            const percentage = ((value / totalContacts) * 100).toFixed(1);
                            return `${value} (${percentage}%)`;
                          },
                          color: '#e5e7eb',
                          font: { weight: 'bold' },
                          padding: 6
                        },
                      },
                      ...(chartType !== 'Pie' && {
                        scales: {
                          y: {
                            beginAtZero: true,
                            ticks: { 
                              precision: 0,
                              color: '#e5e7eb',
                              font: { size: 11 }
                            },
                            grid: {
                              color: 'rgba(55, 65, 81, 0.5)',
                              drawBorder: false
                            }
                          },
                          x: {
                            ticks: {
                              color: '#e5e7eb',
                              font: { size: 11 }
                            },
                            grid: {
                              display: false
                            }
                          }
                        }
                      })
                    }
                  }
                )
              ) : (
                <div className="h-full flex flex-col items-center justify-center text-gray-400">
                  <Activity className="w-12 h-12 mb-3 opacity-50" />
                  <span className="text-lg">No data available</span>
                  <span className="text-sm text-gray-500">Try adjusting your filters or fetching new contacts</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
  
      <TrendView
        selectedProperty={selectedProperty}
        isVisible={showTrendView}
        onClose={() => setShowTrendView(false)}
      />
    </div>
  );
};

export default ChartComponent;
